:root {  
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */   
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1500px;
    --fixed-header-height: 80px;
   
    /* Slick */
    --slick-slider-height: 45vh;
    
    /* change settings for headings */
    --text-color: #222;
    --body-bg: #fff;
    --main-color: #eee;
    --accent-color: #810021;
    --effect-color: #222;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --footer-bg-color: var(--main-color);
    --footer-text-color: inherit;
    --menu-bg-color: inherit;
    --menu-bg-hover: var(--accent-color);
    --menu-li-a-padding: 10px 15px;
    --link-color: inherit;
    --link-hover-color: inherit;
    --map-marker-color: 000;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: Questrial, Helvetica, Arial, sans-serif;
    --alternative-font-family: Questrial, Helvetica, Arial, sans-serif;  
}

@import "/cssmodules/normalize.css";
@import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fonts/Questrial/all.css"; /* 400 */
@import "/fonts/Roboto/all.css"; /* 100, 100i, 300, 300i, 400, 400i, 500, 500i, 700, 700i, 900, 900i */
/*@import "/cssmodules/animate.css";*/

/*--------------------------------------------
General
---------------------------------------------*/
img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    background: url('/theme/intendit/graphics/bg4.jpg');
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4 {
    font-family: var(--alternative-font-family);
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h1.twelve {
    max-width: 100%;
}

.container h1 {
    margin: 2rem 0.8rem;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels {
    overflow: visible !important;
}

.fa-map-marker {
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*--------------------------------------------------------
Header
---------------------------------------------------------*/
header {
    /*background: var(--header-bg-color);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);*/
}

header .container {
    padding :0 0.5em;
}

header a {
    text-decoration: none;
}

.nav-outer {
    /*background: var(--accent-color); */
}

.logo {
    padding: 1.5rem;
}

.logo a {
    font-size: 0;
}


.menu li {
    margin:0;
}

.menu li a {
    padding: var(--menu-li-a-padding);
    color: var(--accent-color); 
    transition: 0.5s ease-in;    
}

.menu li a:hover {
    transition: 0.5s ease-in;
    color: #222;
}

.menu .active a, .menu .active:hover a {
    color: #222;
}

.activeparent li {
    color: var(--accent-color);
}

/**** Dropdown ****/

.menu .dropdown ul {
    z-index: 100;
    border-bottom: none;
}

.menu li:hover ul {
    margin-left: 0;
}

.menu .sub-menu-items li a { 
    margin: 0; 
    color: #222;
}

.menu.menu .sub-menu-items li a:hover { 
    color: #fff;
}

/**** Leftmenu ****/

.submenu ul {
    margin-top: 2.8rem;
    padding: 0;
}

.submenu ul li {
    list-style-type: none;
    padding: var(--padding);
    border-bottom: 1px solid #eee;
    background: #fff;
} 

.submenu ul li a  {
    text-decoration: none;
    color: var(--effect-color);
}

.submenu li a:hover, .submenu .active a {
    color: var(--accent-color);
}

/*--------------------------------------------------------
Slider startsida
---------------------------------------------------------*/
.slick-slider {
    margin-bottom: 0;
}

.topslider .slick-slide {  
    height: var(--slick-slider-height)
}

.topslider .slick-slide {
    background-size: cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.topslider .slick-title {
    text-align:center;
    color:white;
    /*text-shadow: 1px 1px 2px rgba(0,0,0,0.5);*/
}

/** arrows **/
.topslider .slick-prev, .topslider .slick-next {
    width:auto;
    height:auto;
}

.topslider .slick-next {
    right:50px;
    z-index:100;
}

.topslider .slick-prev {
    left:50px;
    z-index:100;
}

.topslider .slick-next, .topslider .slick-prev {
    opacity: 1;
}

.topslider .slick-next:hover, .topslider .slick-prev:hover {
    border:none;
}

.topslider .slick-next:before, .topslider .slick-prev:before {
    transition: 0.8s ease;
    background:rgba(255,255,255,0.7);
    padding: 1.5em;
    font-family: Roboto;
    line-height: normal;
    color: #222;
}

.topslider .slick-next:hover:before, .topslider .slick-prev:hover:before {
    background: white;
    color: black;
}

.topslider .slick-next:before {
    content:"»";
}

.topslider .slick-prev:before {
    content:"«";
}

/* Content-startpage | Blocks
---------------------------------------------------------*/

.introcontent  {
    margin: 0 auto;
    text-align: center;
}

/* Content - all
---------------------------------------------------------*/



/* Boxar / Zoomboxar
-------------------------------------------------*/

.boxes a {
    text-decoration: none;
    color: #fff;
}

.boxes a:hover {
    color: #ccc;
}

.boxbutton, button, input[type=reset], input[type=submit] {
    border: 1px solid #fff;
    border-radius: 2px;
    padding: 0.5rem;
}

.boxwrapper {
    height: 38vh;
    background-size: cover;
    background-position:center;
    filter: grayscale(1);
}

.boxwrapper:hover {
    filter: grayscale(0);
}

.zoombox .boxwrapper {
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #fff;
    text-align:center;
    text-shadow: 1px 1px 2px rgba(124,0,82,0.9);
    z-index: 3;
    font-weight: 700;
    font-size: 3rem;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform 3s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform 3s ease-in 0s;
}

.zoombox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background 3s ease-in 0s;
    z-index: 2;
}

.zoombox .boxwrapper:hover:after {
    background: rgba(129,0,33,0.8);
    transition: background 3s ease-out 0s;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.1);
    transition: transform 3s ease-out 0s;
}

.contentboxes .boxwrapper {
    height: 15vh;
    margin-top: 50px;
}

.contentboxes h2 {
    font-size: 1.5rem;
}


.zoombox .box-teaser {
    z-index: 101;
    opacity: 0;
    text-align: center;
    transition: 0.5s ease;
    color: white;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity:1;
}

/* Contact
---------------------------------------------------------*/


.kontakt svg {
    margin-right: 0.5rem;
}


/* listing items
---------------------------------------------------------*/

.mfp-title {
    display: none;
}

hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.artisteri-item, .maincontent {
    background: rgba(0, 0, 0, .1);
}

.movie-link {
    width: 500px;
    height: 300px;
    background-size: cover;
}

.movie-link svg{
    fill: white;
}

.movie-link .fa-icon {height:60px; width: auto; vertical-align:middle !important; }

.taxonomylink  a {
    text-decoration: none;
}

.taxonomylink  a span {
    text-decoration: underline;
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}

/* Footer
---------------------------------------------------------*/

footer {
    background: var(--effect-color);
    color: #ccc;
}

footer h3 {
    font-weight: 100;
}

footer a {
    text-decoration:none;
}

footer svg.fa-icon {
    fill: #ccc;
    height: calc(1 * var(--scale-factor)rem);
    transition: ease-in 0.5s;
    margin-right: 7px;
}

footer svg.fa-icon:hover {
    fill: var(--accent-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background: var(--accent-color);
    padding: 0.5em 0;
    text-align: right;
}

.created-by .container, .intenditfooter {
    padding: 0;
    margin: 0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
     color: #510e3a;
}


/*------------------------------------------
Sticky footer
-------------------------------------------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type=email], input[type=number], input[type=search], input[type=text], input[type=tel], input[type=url], input[type=password], textarea {
    width: 100%;
    color: #222;
    padding: 1rem;
}

button[type=submit], input[type=submit] {
    margin:0;
    background: var(--accent-color);
    float: right;
    border: none;
    transition: ease-in 0.5s;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: var(--effect-color);
    color: #fff;
    border: none;
}

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*Not found*/
.pagenotfound {min-height: 100vh; position:relative; text-align:center; font-size:1.1em; }
.pagenotfound .logo img {max-height: 70px; width:auto; max-width: 100%; display:block; }
.pagenotfound ul {list-style-type:none; padding: 0; margin: 0.5em auto; width:100%; max-width: 550px; }
.pagenotfound li a { border-bottom: 1px dashed #d0d0d0; display: inline-block;  padding: 10px 110px; }

/* Media Queries
---------------------------------------------------------*/

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
    
    .logo img {
        max-width: 60%;
        height: auto;
    }
    
    .logo {
        text-align: center;
        padding: 0;
    }
    
    .artisteri h1 {
        padding: 0;
        margin: 0;
    }
    
    footer {
        text-align: center;
    }
    
    .boxwrapper h2 {
        font-size: 2rem;
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    
    .intenditfooter {
        text-align: center;
    }
    
     .logo img {
        max-width: 90%;
        height: auto;
    }
}


@media (max-width: var(--breakpoint-s)) { 
    .logo img {
        max-width: 80%;
    }
}

@media (max-width: var(--breakpoint-menu)) {
    
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border: 1px solid;
    }
   
    .menu .dropdown ul {
        border: none !important;
        background: var(--accent-color) !important;
        width: 100%;
        padding-left: 30px;
    }
    
    .menu .dropdown ul li a {
       font-size: .8rem;
    }
    
    .menu li, .menu li ul, .menu li:hover ul {
        margin: 0 !Important;
    }
    
    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
        background: var(--accent-color);
        color: #fff;
    }
    
    .menu .active a {
        color: #fff;
        background: var(--effect-color) !important;
    }
}
